import React from 'react';
import { Grid, Link, Slide, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import LogoImage from '../../assets/images/logo.svg';
import './SuccessInstall.scss';

const link = "https://microacquire.com?utm_source=slack&utm_medium=install&utm_content=thankyou&utm_campaign=alerts_install";

export const SuccessInstall: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <>
      <Grid container item xs={12} sm={12} lg={12} justify='center' alignItems='center'>
          <Grid item xs={12} sm={6} lg={6}>
            <Slide in={true} direction='up' timeout={2000}>
              <div className={classNames({
                'FeaturesContainerSmall': isSmallScreen,
                'SuccessInstallTitleContainer': !isSmallScreen,
                'FeaturesXSmallContainer' : isExtraSmallScreen,
              })}>
                <img
                    className='SuccessInstallLogoImg'
                    alt='logo'
                    style={{ width: '222px', transform: isSmallScreen ? 'scale(0.7)' : 'none' }}
                    src={LogoImage}
                ></img>
                <Typography className={isSmallScreen ? "SecondaryTitleSmall" : "SecondaryTitle"} variant='h6'>Success!</Typography>
                <Typography align="left" className={isSmallScreen ? "SecondaryContentSmall" : "Content"} variant='subtitle1'>
                  Thank you for installing the MicroAcquire App for Slack.
                  From now on, you'll receive instant listings alerts in Slack when startups go live on <Link underline="always" href={link}>MicroAcquire</Link>.
                </Typography>
              </div>
            </Slide>
        </Grid>
      </Grid>
    </>
  );
};

export default SuccessInstall;
