import React from 'react';
import {Grid, Typography, Slide, useTheme, useMediaQuery} from '@material-ui/core';
import './Footer.scss';

export const Footer: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
      <Grid container direction='column' justify='center' alignItems='center' spacing={3}>
        <Grid className="FooterSupportSection" container item xs={12} sm={12} lg={12}>
            <Slide direction='down' in={true} timeout={1000}>
                <div className="FooterContainer">
                    <Typography className={isSmallScreen ? 'FooterContentSmall' : "FooterContent"} variant='body2'>
                        Have any ideas, feedback or need help? Feel free to contact us at <br/><strong>support@microacquire.com</strong>
                    </Typography>
                </div>
            </Slide>
        </Grid>
        <Grid className="FooterPrivacyPolicy" container item xs={12} sm={12} lg={12}>
            <Slide direction='down' in={true} timeout={1000}>
                <div className='FooterContainer ContentSmall'>
                    <a href={"https://microacquire.com/privacy"}>Privacy Policy | MicroAcquire</a>
                </div>
            </Slide>
        </Grid>
      </Grid>
  );
};
