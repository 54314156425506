import React from 'react';
import classNames from "classnames";
import {Button, Grid, Slide, Typography, useMediaQuery, useTheme} from '@material-ui/core';
import LogoImage from '../../assets/images/logo.png';
import AllowButtonImage from '../../assets/images/allow-button.svg';
import './Setup.scss';

export const Setup: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <Grid className={isSmallScreen ? 'SetupRootSmall': 'SetupRoot'} container direction='column' justify='center' alignItems='center' spacing={2}>
      <Grid container item xs={12} sm={12} lg={12} justify='center' alignItems='center'>
        <Grid item xs={12} sm={12} lg={12}>
          <Slide in={true} direction='up' timeout={2000}>
            <div className='SetupContainer'>
              <Typography className={isSmallScreen ? "SecondaryContentSmall" : "Content"} variant='subtitle1'>How to set up</Typography>
              <Typography className={isSmallScreen ? "SecondaryTitleSmall" : "SecondaryTitle"} variant='h6'>MicroAcquire</Typography>
            </div>
          </Slide>
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={12} lg={12} justify='center' alignItems='center' spacing={3}>
        <Grid item xs={12} sm={4} lg={4}>
          <Slide in={true} direction='up' timeout={2200}>
            <div className={isSmallScreen ? 'SetupSmallContainer' : 'SetupContainer'}>
              <Typography className='SetupSubtitle' variant='subtitle1'>Step 1</Typography>
              <Typography className={classNames('ContentSmall', 'StepContent', 'FirstStep')} variant='subtitle2'>
                Click <strong>Add to Slack</strong> and install the MicroAcquire app.
              </Typography>
              <Button className={isExtraSmallScreen ? 'SetupSlackButtonSmall' : 'SetupSlackButton'} variant="text" href="https://slack.com/oauth/v2/authorize?client_id=1963550662352.3550193443537&scope=chat:write,commands,im:write,users:read,users:read.email&user_scope=">
                <img alt="Add to Slack" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
              </Button>
            </div>
          </Slide>
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
          <Slide in={true} direction='up' timeout={2200}>
            <div className={isSmallScreen ? 'SetupSmallContainer' : 'SetupContainer'}>
              <Typography className='SetupSubtitle' variant='subtitle1'>Step 2</Typography>
              <Typography className={classNames('ContentSmall', 'StepContent', 'SecondStep')} variant='subtitle2'>
                Select the workspace to add <strong>“MicroAcquire”</strong> to and click <strong>Allow</strong>.
              </Typography>
              <img
                  alt='Add to workspace button'
                  style={{ width: '118px', transform: isSmallScreen ? 'scale(0.7)' : 'none' }}
                  src={AllowButtonImage}
              ></img>
            </div>
          </Slide>
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
          <Slide in={true} direction='up' timeout={2200}>
            <div className={isSmallScreen ? 'SetupSmallContainer' : 'SetupContainer'}>
              <Typography className='SetupSubtitle' variant='subtitle1'>Step 3</Typography>
              <Typography className={classNames('ContentSmall', 'StepContent', 'ThirdStep')} variant='subtitle2'>
                Voila! The app is installed – begin receiving instant startup acquisition notifications.
              </Typography>
              <img
                  className='SetupLogoImg'
                  alt='logo'
                  style={{ width: '173px', transform: isSmallScreen ? 'scale(0.7)' : 'none' }}
                  src={LogoImage}
              ></img>
            </div>
          </Slide>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Setup;
