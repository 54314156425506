import React from 'react';
import { Button, Grid, Slide, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import { Features } from '../../components/Features/Features';
import { Setup } from '../../components/Setup/Setup';
import { Footer } from '../../components/Footer/Footer';
import SlackPageImage from '../../assets/images/slack.svg';
import './HomePage.scss';

export const HomePage: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <>
      <Grid container direction='column' justify='center' alignItems='center' spacing={2}>

        <Grid className='HomePageRoot' container item xs={12} sm={12} lg={12} justify='center' alignItems='center' spacing={3}>
          <Grid item xs={12} sm={6} lg={6}>
            <Slide direction='down' in={true} timeout={1000}>
              <div className={classNames({
                'HomePageContentSmall': isSmallScreen,
                'HomePageContent': !isSmallScreen,
                'HomePageContentXSmall': isExtraSmallScreen,
              })}>
                <img
                  className='HomePageImage'
                  alt='MicroAcquire Slack page'
                  style={{ transform: isSmallScreen ? 'scale(1)' : 'none' }}
                  src={SlackPageImage}
                ></img>
              </div>
            </Slide>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Slide direction='down' in={true} timeout={1000}>
              <div className={classNames({
                'HomePageSmallContainer': isSmallScreen,
                'HomePageContainer': !isSmallScreen,
                'HomePageXSContainer': isExtraSmallScreen,
              })}>
                <Typography variant='h5' className={isSmallScreen ?'HomePageSmallTitle' : 'HomePageTitle'}>MicroAcquire Alerts</Typography>
                <Typography variant='body1' className={isSmallScreen ? "ContentSmall" : "Content"}>
                  MicroAcquire connects you with 1,000s of vetted startups available for acquisition right within Slack.
                  Integrate the app and receive instant alerts when new startups go live, helping you find the perfect
                  acquisition opportunity on MicroAcquire.
                </Typography>
                <Button className={isSmallScreen ? 'HomePageSmallButton' : 'HomePageButton'} variant="text" href="https://slack.com/oauth/v2/authorize?client_id=1963550662352.3550193443537&scope=chat:write,commands,im:write,users:read,users:read.email&user_scope=">
                  <img className='Image' alt="Add to Slack" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
                </Button>
              </div>
            </Slide>
          </Grid>
        </Grid>
        <Features />
        <Setup />
      </Grid>
      <Footer />
    </>
  );
};

export default HomePage;
