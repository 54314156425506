import React from 'react';
import {Grid, Slide, Typography, useMediaQuery, useTheme} from '@material-ui/core';
import ListingImage from '../../assets/images/listing.svg';
import MenuImage from '../../assets/images/menu.png';
import SummaryImage from '../../assets/images/summary.svg';
import './Features.scss';
import classNames from "classnames";

export const Features: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <>
      <Grid className='FeaturesRoot' container item xs={12} sm={12} lg={12} justify='center' alignItems='center' spacing={3}>
        <Grid item xs={12} sm={12} lg={12}>
          <Slide in={true} direction='up' timeout={2000}>
            <div className= {classNames({
                'FeaturesTitleSmallContainer': isSmallScreen,
                'FeaturesTitleXSmallContainer': isExtraSmallScreen,
                'FeaturesTitleContainer': !isSmallScreen,
            })}>
              <Typography className={isSmallScreen ? "SecondaryTitleSmall" : "SecondaryTitle"} variant='h6'>SaaS, Ecommerce, Crypto, Shopify apps, and other startups in Slack</Typography>
              <Typography className={isSmallScreen ? "SecondaryContentSmall" : "FeaturesContent"} variant='body1'>
                Be the first to know. MicroAcquire lists new startups looking to be acquired every day.
              </Typography>
            </div>
          </Slide>
        </Grid>
      </Grid>
      <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
        <Grid container item xs={12} sm={12} lg={12} justify='center' alignItems='center' spacing={3}>
          <Grid item xs={12} sm={6} lg={6}>
            <Slide in={true} direction='up' timeout={2200}>
              <div className={classNames({
                'FeaturesContainer' : !isSmallScreen,
                'FeaturesXSmallContainer' : isExtraSmallScreen,
                'ContentLeft': !isSmallScreen,
                'ContentSmallLeft': isSmallScreen,
              })}>
                <Typography className={isSmallScreen ? "SecondaryTitleSmall" : "SecondaryTitle"} variant='h6'>Real-time alerts of new startup listings</Typography>
                <Typography className={isSmallScreen ? "SecondaryContentSmall" : "Content"} variant='subtitle1'>
                  Receive instant Slack notifications as we take new startups live on MicroAcquire.
                </Typography>
              </div>
            </Slide>
          </Grid>

          <Grid item xs={12} sm={6} lg={6}>
            <Slide in={true} direction='up' timeout={2200}>
              <div className={isSmallScreen ? 'FeaturesContainerSmall' : 'FeaturesContainer'}>
                <img
                    alt='Startup summary'
                    style={{ height: '318px', width: '393px', transform: isSmallScreen ? 'scale(0.8)' : 'none' }}
                    src={SummaryImage}
                ></img>
              </div>
            </Slide>
          </Grid>
        </Grid>

        <Grid className={isExtraSmallScreen ? 'FeaturesXSmallGrid' : 'FeaturesGrid'} container item xs={12} sm={12} lg={12} justify='center' alignItems='center' spacing={3}>
          <Grid item xs={12} sm={6} lg={6}>
            <Slide in={true} direction='up' timeout={2400}>
              <div className={isSmallScreen ? 'FeaturesContainerSmall' : 'FeaturesContainer'}>
                <img
                    alt='Startup listing'
                    style={{ height: '320px', width: '395px', transform: isSmallScreen ? 'scale(0.8)' : 'none' }}
                    src={ListingImage}
                ></img>
              </div>
            </Slide>
          </Grid>

          <Grid item xs={12} sm={6} lg={6}>
            <Slide in={true} direction='up' timeout={2400}>
              <div className={classNames({
                'FeaturesContainer' : !isSmallScreen,
                'ContentRight': !isSmallScreen,
                'ContentRightLeft': isSmallScreen,
                'FeaturesXSmallContainer' : isExtraSmallScreen,
              })}>
                <Typography className={isSmallScreen ? "SecondaryTitleSmall" : "SecondaryTitle"} variant='h6'>Quick and easy access</Typography>
                <Typography className={isSmallScreen ? "SecondaryContentSmall" : "Content"} variant='subtitle1'>
                  Links directly to the listing’s public profile page to quickly view more details and chat with the founder. Easily share the same link with partners and stakeholders not already on MicroAcquire.
                </Typography>
              </div>
            </Slide>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={12} lg={12} justify='center' alignItems='center' spacing={3}>
          <Grid item xs={12} sm={6} lg={6}>
            <Slide in={true} direction='up' timeout={2600}>
              <div className={classNames({
                'FeaturesContainer' : !isSmallScreen,
                'ContentLeft': !isSmallScreen,
                'ContentSmallLeft': isSmallScreen,
                'FeaturesXSmallContainer' : isExtraSmallScreen,
              })}>
                <Typography className={isSmallScreen ? "SecondaryTitleSmall" : "SecondaryTitle"} variant='h6'>Customize notifications by startup category, revenue, and profit</Typography>
                <Typography className={isSmallScreen ? "SecondaryContentSmall" : "Content"} variant='subtitle1'>
                  Filter new listing alerts by the criteria that matter to you.
                  Go to filters and select your preferred startup category, revenue and profit
                </Typography>
              </div>
            </Slide>
          </Grid>

          <Grid item xs={12} sm={6} lg={6}>
            <Slide in={true} direction='up' timeout={2600}>
              <div className={isSmallScreen ? 'FeaturesContainerSmall' : 'FeaturesContainer'}>
                <img
                  alt='Customize menu'
                  style={{ height: '527px', width: '429px', transform: isSmallScreen ? 'scale(0.8)' : 'none' }}
                  src={MenuImage}
                ></img>
              </div>
            </Slide>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={12} lg={12} justify='center' alignItems='center' spacing={3}>
          <Grid item xs={12} sm={6} lg={6}>
            <Slide in={true} direction='up' timeout={2000}>
              <div className= {classNames({
                'FeaturesContainerSmall': isSmallScreen,
                'FeaturesTitleContainer': !isSmallScreen,
                'FeaturesXSmallContainer' : isExtraSmallScreen,
              })}>
                <Typography className={isSmallScreen ? "SecondaryTitleSmall" : "SecondaryTitle"} variant='h6'>Easily disable and enable app notifications</Typography>
                <Typography className={isSmallScreen ? "SecondaryContentSmall" : "Content"} variant='subtitle1'>
                  On vacation? Pause MicroAcquire notifications using the “Disable notifications” shortcut. Turn them on again using the “Enable notifications” shortcut.
                </Typography>
              </div>
            </Slide>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Features;
