import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { CssBaseline } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import HomePage from './pages/HomePage/HomePage';
import SuccessInstall from './pages/SuccessInstall/SuccessInstall';
import './App.scss';

const App: React.FC = () => {
  return (
    <div className='App'>
      <StylesProvider injectFirst>
        <CssBaseline />
        <Router>
          <Switch>
            <Route exact path="/success-install">
              <SuccessInstall />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </Router>
      </StylesProvider>
    </div>
  );
}

export default App;
